<template>
    <div class="user-agreement">
        <span class="user-agreement-top">面具约会-用户协议</span><br/>
        <br/>
        <span class="user-agreement-title">特别提示</span><br/>
      本协议约定鑫盛计算机科技与用户之间关于“面具约会”软件服务（以下简称“服务”）的权利义务。“用户”是指下载、安装、注册、登录、使用（以下统称“使用”）本服务的个人。广州鑫盛计算机科技有限公司（以下简称“鑫盛计算机科技”）在此特别提醒您（用户）在注册成为用户之前，请认真阅读本《用户协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读并充分理解本协议，特别是涉及免除或者限制责任的条款、权利许可和信息使用的条款、同意开通和使用特殊单项服务的条款、法律适用和争议解决条款等。其中，免除或者限制责任条款等重要内容将以加粗形式提示用户注意，您应重点阅读。如您不同意本协议，这将导致鑫盛计算机科技无法为您提供完整的产品和服务，您也可以选择停止使用。如您自主选择同意或使用面具约会软件及相关服务，则视为您已充分理解本协议，并同意作为本协议的一方当事人接受本协议以及其他与面具约会软件及相关服务相关的协议和规则（包括但不限于《面具约会隐私政策》）的约束。
      <br/>
      <br/>
      为给用户提供更好的服务或因国家法律法规、政策调整、技术条件、产品功能等变化需要，本协议可由鑫盛计算机科技适时修订更新，修订的内容构成本协议的组成部分。本协议更新后，鑫盛计算机科技会通过官方网站公告或其他适当的方式提醒用户更新的内容，以便用户及时了解本协议的最新版本，用户也可以在网站首页或软件设置页面查阅最新版本的协议条款。如用户继续使用“面具约会”软件及相关服务，即表示用户已同意接受修订后的本协议内容。
      <br/>
      <br/>
      <span class="bold-del">请您充分理解并同意，本平台禁止18周岁以下用户注册及使用相关服务，如您的年龄小于18周岁，您将无法注册本产品。出于用户管理及未成年人保护之目的，依据相关规定，我们会基于用户行为对未成年进行识别，如判断您的行为可能是未成年人，将要求您进行实名认证才能继续使用本产品，完成实名认证确认成年后，可恢复使用APP功能，否则将无法继续使用任意社交功能；如判断您是未成年人，平台将对您进行封号处理，禁止您的账号登录；同时我们也为监护人提供了青少年模式，监护人可设置密码，限制未成年人访问，请监护人主动选择开启青少年模式；</span>
      <br/>
      <br/>
      <span class="user-agreement-title">一、账号注册</span><br/>
      1、用户在使用本服务前需要注册一个“面具约会”账号。“面具约会”账号应当使用手机号码注册，请用户使用尚未与“面具约会”账号绑定的手机号码，以及未被鑫盛计算机科技根据本协议封禁的手机号码注册“面具约会”账号。鑫盛计算机科技可以根据用户需求或产品需要对账号注册和绑定的方式进行变更。
      <br/>
      2、“面具约会”系基于地理位置的移动社交产品，用户使用APP时可授权鑫盛计算机科技公开及使用其地理位置信息。
      <br/>
      3、鉴于“面具约会”账号的注册方式，用户同意鑫盛计算机科技在注册时将使用用户提供的手机号码及/或自动提取用户的手机号码及自动提取用户的手机设备识别码等信息用于注册。用户同意给予运营商授权，授权运营商有权自动提取用户的手机号码进行认证并用于“面具约会”账号注册，用户保证遵守运营商的相关服务条款，如运营商对用户的手机号认证成功，则用户的注册即完成。如用户不同意对运营商的授权和/或服务条款或者是用户的手机号认证失败，用户可以手动修改运营商提取的手机号码，采取验证码方式进行注册登录。
      <br/>
      <span class="bold">4、用户理解并承诺，用户所设置的账号不得违反国家法律法规及面具约会的相关规则，用户的账号名称、头像和简介等注册信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像等或采取其他足以让人引起混淆的方式）开设账号，不得恶意注册面具约会账号（包括但不限于频繁注册、批量注册账号等行为）。用户在账号注册及使用过程中需遵守相关法律法规，不得实施任何侵害国家利益、损害其他公民合法权益，有害社会道德风尚的行为。鑫盛计算机科技有权对用户提交的注册信息进行审核。</span>
      <br/>
      5、在用户注册及使用本服务时，鑫盛计算机科技需要收集能识别用户身份的个人信息以便鑫盛计算机科技可以在必要时联系用户，或为用户提供更好的使用体验。鑫盛计算机科技收集的信息包括但不限于用户的头像、昵称、年龄、性别；鑫盛计算机科技对这些信息的使用将受限于第三条用户个人隐私信息保护的约束。
      <br/>
      <span class="user-agreement-title">二、服务内容</span><br/>
      1、本服务的具体内容由鑫盛计算机科技根据实际情况提供，包括但不限于授权用户通过其账号进行即时通讯、发布动态、互动交流。为更好的提升用户体验及服务，鑫盛计算机科技将不定期变更其提供的服务（包括但不限于软件修改、升级、功能强化、开发新服务、软件替换等）；用户将会收到鑫盛计算机科技关于服务变更的通知（包括但不限于系统提示、公告、站内信等）。用户有权选择接受更新后版本；如用户选择不作更新，则本服务的部分功能将受到限制或不能正常使用。
      <br/>
      2、鑫盛计算机科技许可用户一项个人的、可撤销的、不可转让的、非独占的和非商业的合法使用面具约会软件及相关服务的权利。本协议未明示授权的其他一切权利仍由鑫盛计算机科技保留，用户在行使该些权利前须另行获得鑫盛计算机科技的书面许可，同时鑫盛计算机科技如未行使前述任何权利，并不构成对该权利的放弃。
      <br/>
      3、鑫盛计算机科技提供的服务包含免费服务与收费服务。用户可以通过付费方式购买收费服务，具体方式为：用户通过微信或其他“面具约会”平台提供的付费途径支付一定数额的人民币购买“面具约会”平台的付费服务，如解锁微信、开通会员等。对于收费服务，鑫盛计算机科技会在用户使用之前给予用户明确的提示，只有用户根据提示确认其同意按照前述支付方式支付费用并完成了支付行为，用户才能使用该等收费服务。支付行为的完成以银行或第三方支付平台生成“支付已完成”的确认通知为准。
      <br/>
      <span class="user-agreement-title">三、用户个人信息保护</span><br/>
      1、用户在注册账号或使用本服务的过程中，可能需要填写并提交一些必要的个人信息，如法律法规、规章规范性文件（以下称“法律法规”）规定的需要填写的身份信息。如用户填写、提交的信息不完整或不符合法律法规的规定，则用户可能无法使用本服务或在使用本服务的过程中受到限制。
      <br/>
      2、用户个人信息包括：1）用户自行提供的用户个人信息（如注册时填写的手机号码等个人信息，用户开启定位功能并使用服务时的地理位置信息等）；2）其他方共享的用户个人信息；3）鑫盛计算机科技为提供服务而合法收集的用户个人信息（如使用服务时系统自动采集的设备或软件信息，浏览历史信息，时间信息等信息）。其中个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。用户知悉、理解并同意，用户在使用鑫盛计算机科技的产品与/或服务时，鑫盛计算机科技将按照平台公布的《面具约会隐私政策》的约定处理和保护用户的个人信息，因此希望用户能够仔细阅读、充分理解《面具约会隐私政策》的全文，并在需要时，按照《面具约会隐私政策》的指引，作出用户认为适当的选择。
      <br/>
      3、鑫盛计算机科技重视对个人信息的保护，鑫盛计算机科技将采取技术措施和其他必要措施，确保用户个人信息安全，防止在本服务中收集的用户个人信息泄露、毁损或丢失。在发生前述情形或者鑫盛计算机科技发现存在发生前述情形的可能时，鑫盛计算机科技将及时采取补救措施并告知用户，用户如发现存在前述情形亦需立即与鑫盛计算机科技联系。
      <br/>
      4、鑫盛计算机科技未经用户同意不向任何第三方公开、透露用户个人信息。但以下特定情形除外：
      <br/>
        (1) 鑫盛计算机科技根据法律法规规定或有权机关的指示提供用户的个人隐私信息；<br/>
        (2) 由于用户将其用户密码告知他人或与他人共享注册帐户与密码，由此导致的任何个人信息的泄漏，或其他非因鑫盛计算机科技原因导致的个人隐私信息的泄露；<br/>
        (3) 用户自行向第三方公开其个人隐私信息；<br/>
        (4) 用户与鑫盛计算机科技及合作单位之间就用户个人隐私信息的使用公开达成约定，鑫盛计算机科技因此向合作单位公开用户个人隐私信息；<br/>
        (5) 任何由于黑客攻击、电脑病毒侵入及其他不可抗力事件导致用户个人隐私信息的泄露；<br/>
        (6) 用户个人信息已经经过处理无法识别特定个人且不能复原。<br/>
        5、用户同意鑫盛计算机科技可在以下事项中使用用户的个人隐私信息：<br/>
        (1) 鑫盛计算机科技向用户及时发送重要通知，如软件更新、本协议条款的变更；<br/>
        (2) 鑫盛计算机科技内部进行审计、数据分析和研究等，以改进鑫盛计算机科技的产品、服务和与用户之间的沟通；<br/>
        (3) 依本协议约定，鑫盛计算机科技管理、审查用户信息及进行处理措施；<br/>
        (4) 适用法律法规规定的其他事项。<br/>
        除上述事项外，如未取得用户事先同意，鑫盛计算机科技不会将用户个人隐私信息使用于任何其他用途。<br/>
      6、鑫盛计算机科技重视对未成年人的保护。鑫盛计算机科技将依赖用户提供的个人信息判断用户是否为未成年人。任何18岁以下的未成年人均不得注册账号或使用本服务。
      <br/>
      7、鑫盛计算机科技保证在合法、正当与必要的原则下收集、使用或者公开用户个人信息且不会收集与提供的服务无关的用户个人信息。
      <br/>
      8、鑫盛计算机科技十分注重保护用户的个人信息及个人隐私，并制定了《面具约会隐私政策》，用户应当在仔细阅读、充分理解《面具约会隐私政策》后使用鑫盛计算机科技的产品与/或服务，如果用户不同意政策的内容，将可能导致鑫盛计算机科技的产品与/或服务无法正常运行，或者无法达到鑫盛计算机科技拟达到的服务效果。用户亦可以通过“设置”页面里来进行具体查看，鑫盛计算机科技希望通过《面具约会隐私政策》向用户清楚地介绍对用户个人信息的处理方式，因此建议用户完整地阅读《面具约会隐私政策》，以帮助用户更好地保护用户的隐私权。
      <br/>
        <span class="user-agreement-title">四、内容规范</span><br/>
      1、本条所述内容是指用户使用本服务过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于账号头像、名称、用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等发送、回复或自动回复消息和相关链接页面，以及其他使用账号或本服务所产生的内容。
      <br/>
      2、用户不得利用“面具约会”帐号或本服务制作、上载、复制、发布、传播如下法律、法规和政策禁止的内容：<br/>
        (1) 反对宪法所确定的基本原则的；<br/>
        (2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br/>
        (3) 损害国家荣誉和利益的；<br/>
        (4) 歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；<br/>
        (5) 宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；<br/>
        (6) 煽动民族仇恨、民族歧视，破坏民族团结的；<br/>
        (7) 破坏国家宗教政策，宣扬邪教和封建迷信的；<br/>
        (8) 散布谣言，扰乱经济秩序和社会秩序的；<br/>
        (9) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br/>
        (10) 侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；<br/>
        (11) 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；<br/>
        (12) 以非法民间组织名义活动的；<br/>
        (13) 不遵守法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”要求的；<br/>
        (14) 含有法律、行政法规、政策禁止的其他内容的信息。<br/>
        3、用户不得利用“面具约会”帐号或本服务制作、上载、复制、发布、传播如下干扰“面具约会”正常运营，以及侵犯其他用户或第三方合法权益，对网络生态造成不良影响的内容：<br/>
        (1) 使用夸张标题，内容与标题严重不符的；<br/>
        (2) 炒作绯闻、丑闻、劣迹等的；<br/>
        (3) 不当评述自然灾害、重大事故等灾难的；<br/>
        (4) 带有性暗示、性挑逗等易使人产生性联想的；<br/>
        (5) 展现血腥、惊悚、残忍等致人身心不适的；<br/>
        (6) 煽动人群歧视、地域歧视等的；<br/>
        (7) 宣扬低俗、庸俗、媚俗内容的；<br/>
        (8) 可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等的；<br/>
        (9) 含有辱骂、恐吓、威胁内容的；<br/>
        (10) 含有骚扰、垃圾广告、恶意信息、诱骗信息的；<br/>
        (11) 侵害他人个人信息或资料的；<br/>
        (12) 侵害他人肖像权、知识产权、商业秘密等合法权利的；<br/>
        (13) 含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容，对网络生态造成不良影响的信息。<br/>
        <span class="user-agreement-title">五、使用规则</span><br/>
        1、用户在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表鑫盛计算机科技的观点、立场或政策，鑫盛计算机科技对此不承担任何责任。<br/>
        2、用户不得利用“面具约会”帐号或本服务进行如下行为：<br/>
        (1) 提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；<br/>
        (2) 强制、诱导其他用户关注、点击链接页面或分享信息的；<br/>
        (3) 虚构事实、隐瞒真相以误导、欺骗他人的；<br/>
        (4) 利用技术手段批量建立虚假帐号的；<br/>
        (5) 利用“面具约会”帐号或本服务从事任何违法犯罪活动的；<br/>
        (6) 制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；<br/>
        (7) 其他违反法律法规规定、侵犯其他用户合法权益、干扰“面具约会”正常运营或鑫盛计算机科技未明示授权的行为。<br/>
        3、用户须对利用“面具约会”帐号或本服务传送信息的真实性、合法性、无害性、准确性、有效性等全权负责，与用户所传播的信息相关的任何法律责任由用户自行承担，与鑫盛计算机科技无关。如因此给鑫盛计算机科技或第三方造成损害的，用户应当依法予以赔偿。<br/>
        4、鑫盛计算机科技提供的服务中可能包括广告，用户同意在使用过程中显示鑫盛计算机科技和第三方供应商、合作伙伴提供的广告。除法律法规明确规定外，用户应自行对依该广告信息进行的交易负责，对用户因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，鑫盛计算机科技不承担任何责任。<br/>
        5、除非鑫盛计算机科技书面许可，用户不得从事下列任一行为：<br/>
        (1) 删除软件及其副本上关于著作权的信息；<br/>
        (2) 对软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现软件的源代码；<br/>
        (3) 对鑫盛计算机科技拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；<br/>
        (4) 对软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经鑫盛计算机科技授权的第三方工具/服务接入软件和相关系统；<br/>
        (5) 通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；<br/>
        (6) 通过非鑫盛计算机科技开发、授权的第三方软件、插件、外挂、系统，登录或使用鑫盛计算机科技软件及服务，或制作、发布、传播非鑫盛计算机科技开发、授权的第三方软件、插件、外挂、系统。<br/>
        <span class="user-agreement-title">六、付费</span><br/>
        1、用户可通过付费购买“面具约会”平台的增值服务，包括但不限于聊天服务及会员服务，具体价格信息将由鑫盛计算机科技自行决定并在相关服务页面上显示。<br/>
        2、用户确认在进行充值前已经仔细确认过自己的账号并仔细选择了相关操作选项，若因用户自身输入账号错误、操作不当或不了解充值计费方式等因素造成充错账号、错选充值种类等情形而损害自身权益的，鑫盛计算机科技将不会作出任何补偿或赔偿。<br/>
        3、<span class="bold">用户可以通过微信或其他“面具约会”平台提供的充值途径为面具约会账户进行充值。</span>用户确认不会以非法方式或者使用非平台所指定的充值途径进行充值，如果用户违规使用非鑫盛计算机科技认可的充值途径非法充值/购买，则鑫盛计算机科技不保证充值顺利或正确完成，若因此造成用户权益受损，<span class="bold">鑫盛计算机科技将不会作出任何补偿或赔偿，鑫盛计算机科技同时保留随时中止或终止用户面具约会账号部分或全部权限、短期或永久封禁用户的账号及/或禁止用户使用各项充值服务的权利。</span><br/>
      4、<span class="bold">用户确认在进行充值前已经仔细确认过自己的账号并仔细选择及/或输入充值账号、充值金额等相关操作选项，</span>若因用户自身输入账号错误、金额错误、操作不当或不了解充值计费方式等因素造成充错账号、充值金额错误、错选充值种类等情形而损害自身权益的，由此带来的损失由用户自行承担，<span class="bold">鑫盛计算机科技将不会作出任何补偿或赔偿</span><br/>
      5、如果鑫盛计算机科技发现因系统故障或其他任何原因导致的处理错误，无论有利于鑫盛计算机科技还是有利于用户，<span class="bold">鑫盛计算机科技都有权纠正该错误。</span>如果该措施导致用户实际收到的增值服务特权少于用户应获得的，则鑫盛计算机科技在确认该处理错误后会尽快将差额补足至用户的帐户中。如果该错误导致用户实际收到的增值服务特权多于用户应获得的，则无论错误的性质和原因如何，<span class="bold">鑫盛计算机科技有权从用户的帐户中直接扣除差额。</span><br/>
      6、<span class="bold">鑫盛计算机科技有权基于交易安全、运营策略、行业惯例等方面的考虑不时设定或修改涉及交易的相关事项，包括但不限于交易限额、交易次数等。</span>
      7、为更好的向用户提供产品服务，平台会收集记录用户充值所产生的数据，包括但不限于用户充值开会员、解锁微信等产品或服务中产生的行为信息
      <br/>
      8、<span class="bold">因用户自身的原因导致鑫盛计算机科技无法提供购买服务或提供购买服务时发生任何错误而产生的任何损失或责任，由用户自行负责，鑫盛计算机科技不承担责任，</span>包括但不限于：
      <br/>
      (1) 因用户的面具约会账号丢失、被封禁或冻结；<br/>
        (2) 用户将密码告知他人导致的财产损失；<br/>
        (3) 因用户绑定的第三方支付机构账户的原因导致的任何损失或责任；<br/>
        (4) 其他用户故意或者重大过失或者违反法律法规导致的财产损失。<br/>
       <span class="bold">9、用户在使用充值服务时，如出现任何的涉嫌犯罪、违法违规、违反社会主义道德风尚、违反本协议或其他本服务对用户的管理规定的情形，鑫盛计算机科技有权视用户行为的性质及严重程度决定对用户的账号采取暂时或永久封禁措施。鑫盛计算机科技不予返还用户购买面具约会服务时支付的费用，也不会作出任何补偿。</span>
      <br/>
      <span class="bold">10、用户确认并同意如用户主动注销账号，则用户已充值到账的会员权益等视为自动放弃，鑫盛计算机科技不予返还相应的现金价值，也不会作出任何补偿。</span>
      <br/>
      <span class="bold">11、用户保证用于面具约会充值的资金均为用户的合法收入所得，且用户有充分权利按照本协议约定使用，因用户违反本条而产生的任何争议或纠纷，由用户自行处理解决，并承担由此带来的全部责任及法律后果。同时，鑫盛计算机科技发现（包括但不限于主动发现、收到第三方投诉或权力机关通知等情形）存在上述事由，有权单方决定限制、暂停或终止向用户提供充值服务或对用户的账号采取进一步管理措施；如因此给鑫盛计算机科技造成损失的，用户应予以全额赔偿，同时，鑫盛计算机科技有权保存相关信息，并向相关执法/司法机构、主管部门报告。</span>
      <br/>
        <span class="user-agreement-title">七、账户管理</span><br/>
      <span class="bold">1、“面具约会”账号的所有权归鑫盛计算机科技所有，用户完成申请注册手续后，获得“面具约会”账号的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。如果鑫盛计算机科技发现或者有合理理由认为使用者并非账号初始注册人，为保障账号安全，鑫盛计算机科技有权立即暂停或终止向该注册账号提供服务，并有权永久禁用该账号。</span>
      2、用户可以通过“查看与编辑个人资料页”，来查询、更改面具约会”帐户上的个人资料；也可以通过我的动态、我的相册，来查询、更改、删除发布的动态和相册；也可以通过注销账号，来删除用户的个人资料；
      <br/>
      3、用户有责任妥善保管注册账号信息的安全，因用户保管不善可能导致遭受盗号，责任由用户自行承担。用户需要对注册账号的行为承担法律责任。用户同意在任何情况下不使用其他用户的账号或验证码。在用户怀疑他人使用其账号或验证码时，用户同意立即通知鑫盛计算机科技。
      <br/>
      4、用户应遵守本协议的各项条款，正确、适当地使用本服务，如因用户违反本协议中的任何条款，鑫盛计算机科技在通知用户后有权依据协议中断或终止对违约用户“面具约会”帐号提供服务。同时，鑫盛计算机科技保留在任何时候收回“面具约会”帐号、用户名的权利。<br/>
      5、用户可以通过“设置”页面里的“注销账号”页面来进行账号注销服务，鑫盛计算机科技会在完成注销信息同步、个人身份、安全状态、设备信息、侵权投诉等方面的合理和必要的验证后注销账号，并依照用户的要求删除有关用户账号的一切信息，法律法规另有规定的除外。用户确认注销账号是不可恢复的操作，用户应自行备份与面具约会账号相关的信息和数据，用户确认操作之前与面具约会账号相关的所有服务均已进行妥善处理。用户确认并同意注销账号后并不代表本面具约会账号注销前的账号行为和相关责任得到豁免或减轻，如在注销期间，用户的账号被他人投诉、被国家机关调查或者正处于诉讼、仲裁程序中，鑫盛计算机科技有权自行终止用户的账号注销。
      <br/>
        <span class="user-agreement-title">八、风险承担</span><br/>
      <span class="bold">1、用户理解并同意，“面具约会”仅为用户提供信息分享、传送及获取的平台，用户必须为自己注册账号下的一切行为负责，包括用户所传送的任何内容以及由此产生的任何后果。用户应对“面具约会”及本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。鑫盛计算机科技无法且不会对因用户行为而导致的任何损失或损害承担责任。</span>
      <br/>
      2、如果用户发现任何人违反本协议约定或以其他不当的方式使用本服务，请立即向鑫盛计算机科技举报或投诉，举报或APP内联系官方客服反馈，鑫盛计算机科技将依本协议约定进行处理。
      <br/>
      3、用户理解并同意，因业务发展需要，鑫盛计算机科技保留单方面对本服务的全部或部分服务内容变更、暂停、终止或撤销的权利。
      <br/>
      <span class="user-agreement-title">九、知识产权声明</span><br/>
      1、除本服务中涉及广告的知识产权由相应广告商享有外，鑫盛计算机科技在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权均归鑫盛计算机科技所有，但用户在使用本服务前对自己发布的内容已合法取得知识产权的除外。
      <br/>
      2、除另有特别声明外，鑫盛计算机科技提供本服务时所依托软件的著作权、专利权及其他知识产权均归鑫盛计算机科技所有。
      <br/>
      3、鑫盛计算机科技在本服务中所涉及的图形、文字或其组成，以及其他鑫盛计算机科技标志及产品、服务名称（以下统称“鑫盛计算机科技标识”），其著作权或商标权归鑫盛计算机科技所有。未经鑫盛计算机科技事先书面同意，用户不得将鑫盛计算机科技标识以任何方式展示或使用或作其他处理，也不得向他人表明用户有权展示、使用、或其他有权处理鑫盛计算机科技标识的行为。
      <br/>
      4、上述及其他任何鑫盛计算机科技或相关广告商依法拥有的知识产权均受到法律保护，未经鑫盛计算机科技或相关广告商书面许可，用户不得以任何形式进行使用或创造相关衍生作品。
      <br/>
      <span class="bold">5、用户在使用面具约会服务时发表上传的文字、图片、视频、音频等各种形式的内容和信息均由用户原创或已获合法授权（且含转授权），此部分内容和信息的知识产权归用户或原始著作权人，责任由用户承担。用户的发表、上传行为视为对鑫盛计算机科技的授权，用户理解并同意授予鑫盛计算机科技及其关联公司全球范围内完全免费、非独家、永久、可转授权和可再许可的权利，包括但不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及《著作权法》规定的由著作权人享有的其他著作财产权利及邻接权利。鑫盛计算机科技可自行选择是否使用以及使用方式，包括但不限于将前述内容和信息在鑫盛计算机科技旗下的服务平台上使用与传播，将上述内容和信息再次编辑后使用，以及由鑫盛计算机科技授权给合作方使用、编辑与传播等。为避免疑义，用户理解并同意，上述授予的权利包括使用、复制和展示用户拥有或被许可使用并植入内容和信息中的个人形象、肖像、姓名、商标、服务标志、品牌、名称、标识和公司标记（如有）以及任何其他品牌、营销或推广资产、物料、素材等的权利和许可。</span>
      <br/>
      <span class="user-agreement-title">十、法律责任</span><br/>
      <span class="bold">1、如果鑫盛计算机科技发现或收到他人举报或投诉用户违反本协议约定的，鑫盛计算机科技有权对用户在平台内产生的数据（包括但不限于用户资料、动态等）进行合规审查，并视情节轻重对违规账号处以包括但不限于警告、拒绝发布、删除内容、限制账号部分或者全部功能直至终止提供服务、永久关闭账号等措施，对于因此而造成用户无法正常使用账号及相关服务、无法正常获取用户账号内资产或其他权益等后果，鑫盛计算机科技不承担任何责任，鑫盛计算机科技将通知用户处理结果，对已删除内容鑫盛计算机科技有权不予恢复。</span>
      <br/>
      <span class="bold">2、因违反平台规则被封禁的用户，可以通过客服邮件向鑫盛计算机科技提交申诉，鑫盛计算机科技将对申诉进行审查，并自行合理判断决定是否变更处罚措施。</span>
      <br/>
      <span class="bold">3、用户理解并同意，鑫盛计算机科技有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。</span>
      <br/>
      <span class="bold">4、用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿鑫盛计算机科技与合作公司、关联公司，并使之免受损害。</span>
      <br/>
      <span class="bold">5、鑫盛计算机科技尊重并保护用户及他人的知识产权、名誉权、姓名权、隐私权等合法权益。用户保证，在使用本服务时上传的文字、图片、视频、音频、链接等不侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等权利及合法权益。否则，鑫盛计算机科技有权在收到权利方或者相关方通知的情况下移除该涉嫌侵权内容。针对第三方提出的全部权利主张，用户应自行处理并承担可能因此产生的全部法律责任；如因用户的侵权行为导致鑫盛计算机科技及其关联方遭受损失的（包括但不限于经济、商誉等损失），用户还应足额赔偿鑫盛计算机科技及其关联方遭受的全部损失。</span>
      <br/>
      <span class="bold">6、用户在使用鑫盛计算机科技提供的服务时，如出现违反国家法律法规、本协议约定或其他本平台对用户的管理规定的情形，鑫盛计算机科技有权暂时或永久封禁用户的账号。</span>
      <br/>
       <span class="user-agreement-title">十一、不可抗力及其他免责事由</span><br/>
      1、用户理解并确认，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，鑫盛计算机科技将努力在第一时间与相关单位配合，及时进行修复，但是由此给用户或第三方造成的损失，鑫盛计算机科技及合作单位在法律允许的范围内免责。
      <br/>
      <span class="bold">2、本服务同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，威胁到用户的计算机信息和数据的安全，继而影响本服务的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。</span>
      <br/>
      <span class="bold">3、用户理解并确认，本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，因此导致的用户或第三方任何损失，鑫盛计算机科技不承担任何责任。</span>
      <br/>
      <span class="bold">4、用户理解并确认，在使用本服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，鑫盛计算机科技不承担任何责任。</span>
      <br/>
      <span class="bold">5、用户理解并确认，鑫盛计算机科技需要定期或不定期地对“面具约会”平台或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，鑫盛计算机科技无需为此承担任何责任，但鑫盛计算机科技应事先进行通告。</span>
      <br/>
      <span class="bold">6、鑫盛计算机科技依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成鑫盛计算机科技的义务或承诺，鑫盛计算机科技不能保证及时发现违法违规或违约行为或进行相应处理。</span>
      <br/>
      <span class="bold">7、用户理解并确认，对于鑫盛计算机科技向用户提供的下列产品或者服务的质量缺陷及其引发的任何损失，鑫盛计算机科技无需承担任何责任：</span>
      <br/>
      <span class="bold">(1) 鑫盛计算机科技向用户免费提供的服务；</span>
      <br/>
      <span class="bold">(2) 鑫盛计算机科技向用户赠送的任何产品或者服务。</span>
      <br/>
      <span class="bold">8、在任何情况下，鑫盛计算机科技均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因用户使用“面具约会”或本服务而遭受的利润损失，承担责任（即使鑫盛计算机科技已被告知该等损失的可能性亦然）。尽管本协议中可能含有相悖的规定，鑫盛计算机科技对用户承担的全部责任，无论因何原因或何种行为方式，始终不超过用户因使用鑫盛计算机科技提供的服务而支付给鑫盛计算机科技的费用(如有)。</span>
      <br/>
      <span class="user-agreement-title">十二、服务的变更、中断、终止</span><br/>
      1、鉴于网络服务的特殊性，用户同意鑫盛计算机科技有权视情况变更、中断或终止部分或全部的服务（包括收费服务）。鑫盛计算机科技变更、中断或终止的服务，鑫盛计算机科技应当在变更、中断或终止之前通知用户，并应向受影响的用户提供等值的替代性的服务；如用户不愿意接受替代性的服务，如果该用户已经向鑫盛计算机科技支付的费用，鑫盛计算机科技应当按照该用户实际使用服务的情况扣除相应费用之后将剩余的费用退还用户
      <br/>
      2、如发生下列任何一种情形，鑫盛计算机科技有权变更、中断或终止向用户提供的免费服务或收费服务，而无需对用户或任何第三方承担任何责任：
      <br/>
      (1) 根据法律规定用户应提交真实信息，而用户提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；
      <br/>
      (2) 用户违反相关法律法规或本协议的约定；
      <br/>
      (3) 按照法律法规规定或有权机关的要求；
      <br/>
      (4) 出于安全的原因或其他必要的情形。
      <br/>
      <span class="user-agreement-title">十三、会员权限说明</span><br/>
      用户理解并同意不同会员的权限说明，以及退款协议<br/>
      1、双周会员：14天会员时长<br/>
      2、月会员：30天会员时长<br/>
      3、季度会员：90天会员时长<br/>
      4、半年会员：180天会员时长<br/>
      5、年会员：365天会员时长<br/>
      6、终身会员：APP正常运营期内，均享受会员特权；APP若停止服务，则会员自动到期<br/>
      7、退款：若用户对会员服务不满意，可以联系官方客服提供合理理由后，客服将按照用户实际使用服务的情况扣除相应费用之后将剩余的费用退还用户
      <br/>
        <span class="user-agreement-title">十四、其他</span><br/>
      <span class="bold">1、鑫盛计算机科技郑重提醒用户注意本协议中免除鑫盛计算机科技责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。</span>
      <br/>
      <span class="bold">2、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和鑫盛计算机科技之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交鑫盛计算机科技住所地有管辖权的人民法院管辖。</span>
      <br/>
      3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。
      <br/>
      4、由于互联网高速发展，用户与鑫盛计算机科技签署的本协议列明的条款可能并不能完整罗列并覆盖用户与面具约会所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，面具约会隐私权政策、面具约会平台行为规范等均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如用户使用面具约会平台服务，视为用户同意上述补充协议。
        <br/>
    </div>
</template>

<script>
export default {
    name: "UserAgreement"
}
</script>

<style lang="scss" scoped>
.user-agreement {
    text-align: left;
    padding: 15px 15px;
    font-size: 14px;
    line-height: 25px;
    &-top {
        font-size: 25px;
        font-weight: bold;
        line-height: 35px;
    }
    &-title {
        font-size: 18px;
        font-weight: bold;
        line-height: 35px;
    }
}
.bold-del {
  font-weight: bold;
  text-decoration: underline;
}
.bold {
  font-weight: bold;
}
</style>
