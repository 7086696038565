<template>
  <div>
    <div class="user-agreement">
      <p class="user-agreement-top">会员协议</p>
      <p class="user-agreement-title">重要提示</p>
      <br/>
      广州鑫盛计算机科技有限公司	（以下简称“鑫盛计算机科技”）在此特别提醒您，在您注册成为面具约会会员之前，请认真阅读《会员协议》（以下简称“本协议”），确保您充分理解各条款，特别是以黑体加粗或其他合理方式提示您注意的条款，该等条款可能与您的权益存在重大利害关系，包括但不限于鑫盛计算机科技的责任、您享有的权利、争议解决方式及司法管辖条款等，请您审慎阅读并选择接受或不接受本协议。如果您不同意本协议的任一或全部条款内容，请不要以确认的形式（包括但不限于：点击、进入购买程序等）进行下一步操作或使用本协议项下服务。当您确认接受本协议或开始使用本协议项下服务时，即表示您与鑫盛计算机科技已达成协议约定的服务合作关系，您自愿接受并遵守本协议项下的全部约定。
      <br/>
      <br/>
      本协议约定鑫盛计算机科技与您之间关于面具约会会员服务（下称“会员服务”）的各项事项。“会员”是指注册、登录、使用本协议项下鑫盛计算机科技提供的各种服务的个人（以下称“会员”或“您”）。面具约会会员服务是有偿的增值服务，您必须完全同意本协议，并完成付费，才能享受相关服务。
      <br/>
      <br/>
      本协议是“面具约会”软件《用户协议》、《隐私政策》的补充协议，是其不可分割的一部分。同时，鑫盛计算机科技不时发布的关于会员服务的各种规范、规则等也是本协议的一部分。您选择接受本协议，开通会员服务成为会员用户，即表示您同意接受本协议、《用户协议》、《隐私政策》及各种规范、规则的各项约束，并将遵守。如果您不接受本协议，则不能享受本服务。
      <br/>
      <br/>
      本协议可能发生的变更（包括但不限于本协议修改、服务细则调整、会员权益调整等），面具约会将在相关服务页面或以合理方式事先通知您，请您仔细阅读并选择是否按照调整和/或变更后的本协议/服务细则/会员权益等继续选择本服务。如您同意上述调整和/或变更，您可以采用接受服务的形式进行下一步操作或继续使用本服务，以表明您已经接受上述调整和/或变更；如您不同意调整和/或变更，我们建议您立即停止使用本服务。如您对上述调整和/或变更有任何问题，可以咨询面具约会客服
      <br/>
      <br/>
      <p class="user-agreement-title">一、总则</p>
      <br/>
      <br/>
      1.面具约会是由鑫盛计算机科技创建、运营的基于地理位置的移动社交产品。
      <br/>
      <br/>
      2.面具约会用户是指完成全部注册流程，愿意接受面具约会《用户协议》、《隐私政策》并在面具约会相关使用规范的规定下使用面具约会服务的注册用户。
      <br/>
      <br/>
      3.本服务是面具约会为面具约会用户提供的有偿增值服务，用户向面具约会支付相关费用后，方可享受专门为会员提供的各项服务。
      <br/>
      <br/>
      <p class="user-agreement-title">二、服务费用及说明</p>
      <br/>
      <br/>
      1.面具约会会员增值服务为VIP会员，具体资费标准以面具约会App公布的为准，用户可通过选择第三方支付方式购买。鑫盛计算机科技将根据不同的购买方式和数量，向用户提供不同幅度的优惠，具体优惠政策以会员中心相关页面的公告、说明或会员资格支付页面显示的内容为准。
      <br/>
      <br/>
      2.您可以通过登录面具约会-我的-会员中心免费查询您的账号信息详情，包括已开通的服务内容和服务期限等信息。
      <br/>
      <br/>
      3.本服务均仅限于会员在面具约会平台使用，禁止任何以恶意破解等非法手段将本服务内容与面具约会平台分离的行为，由此引起的一切法律后果由行为人负责，面具约会将依法追究行为人的法律责任。
      <br/>
      <br/>
      4.鑫盛计算机科技有权基于自身业务发展需要调整会员服务内容、服务费用标准，调整后的服务内容或费用标准将在会员中心页面显示，用户按调整后的会员服务内容、服务费用标准支付后方可享有会员资格。已按原会员服务费用标准购买会员服务的用户，其会员资格在已取得的会员资格期限内不受影响。
      <br/>
      <br/>
      <p class="user-agreement-title">三、服务的开通、有效期限及终止</p>
      <br/>
      <br/>
      1.用户完成注册程序并通过面具约会平台提供的付费途径完成会员费用的支付后，即取得相应面具约会会员资格。
      <br/>
      <br/>
      2.提交申请并开通会员资格时，您需确保提交的个人资料真实、准确、完整、合法有效。当个人资料发生变化时，应及时对注册资料予以修改、更新，否则由此造成的会员权利不能全面有效行使或其他任何后果、责任由您自行承担。
      <br/>
      <br/>
      3.会员资格有效期自面具约会会员服务开通之时起算，具体期限可以登录面具约会App-我的-会员中心查看。
      <br/>
      <br/>
      4.本协议自会员选择接受或使用本服务之日起生效，直至会员终止本服务/注销会员资格时终止。
      <br/>
      <br/>
      <p class="user-agreement-title">四、会员的权利和义务</p>
      1.面具约会会员平等享有会员的权利，同时应遵守鑫盛计算机科技的各项规范、规则，包括但不限于本协议、《用户协议》、《隐私政策》、《面具约会平台使用规范》等。
      <br/>
      <br/>
      2.因违反本协议第四条第1款所述的相关协议或平台规则导致会员服务被暂停使用而导致的损失，由您自行承担。
      <br/>
      <br/>
      3.会员资格有效期内，会员可以享受相应的增值服务。
      <br/>
      <br/>
      4.在会员资格有效期内，您主动终止或取消会员资格的，鑫盛计算机科技不负责退还与剩余会员资格有效期对应的服务费用。
      <br/>
      <br/>
      5.会员自行承担在面具约会中传送、发布信息及使用面具约会免费服务或收费服务的法律责任，会员使用面具约会服务，包括免费服务与收费服务的行为，均应遵守各项法律法规、规章、规范性文件（以下简称“法律法规”）以及平台协议及规则。
      <br/>
      <br/>
      6.会员资格开通后，您应谨慎合理的保存、使用用户名和手机号，不得将账号借予他人使用。否则，您应承担由此产生的全部责任。同时，面具约会在该种情况下有权作出独立判断，可采取暂停或关闭用户会员资格等措施。
      <br/>
      <br/>
      7.会员服务仅限于申请账号个人使用，不得用于商业等用途；会员服务期内不能在面具约会账号之间转移，不得赠与、借用、转让或售卖。
      <br/>
      <br/>
      8.会员不得以盗窃、利用系统漏洞等非法途径以及在未获面具约会授权的非法销售面具约会会员的网站上获取或购买会员服务，否则因此导致的损失由会员自行承担。
      <br/>
      <br/>
      9.如在扣费过程出现差错，会员应密切配合面具约会查明原因，各自承担己方过错造成的损失；若因双方各自存在不均等过错造成的损失，由双方按过错的程度承担相应责任；双方共负责任的，由双方均摊责任。
      <br/>
      <br/>
      <p class="user-agreement-title">五、鑫盛计算机科技的权利和义务</p>
      <br/>
      <br/>
      1.鑫盛计算机科技应保证会员服务的正常使用，出现技术故障时应尽快排除，但对于会员因此产生的任何损失，鑫盛计算机科技不承担责任。
      <br/>
      <br/>
      2.因不可抗力造成会员服务提供的中断或其它缺陷，鑫盛计算机科技不承担任何责任，但将尽力减少因此给会员造成的损失和影响。
      <br/>
      <br/>
      3.会员应妥善保管面具约会账号，因会员自身原因导致账号泄露而造成的损失，由会员自行承担。但为避免账号被盗用等情形给会员造成损失，面具约会会在您举报后采取一切措施避免第三方对会员账号实施操作行为，而不论该第三方基于何种目的、获取方式是否善意。但面具约会采取措施的前提需会员提供相应的材料以证明其为账号的合法使用者，您应予以配合。
      <br/>
      <br/>
      4.会员在使用面具约会服务（含免费服务及收费服务）时，故意作出有损鑫盛计算机科技、面具约会平台、其他面具约会用户合法权益的行为，或违反《用户协议》等平台规则的，鑫盛计算机科技有权取消会员资格或中断一项或多项会员服务而无须给予任何补偿，与此同时，鑫盛计算机科技保留追究会员法律责任的权利。
      <br/>
      <br/>
      5.会员在使用面具约会服务（含免费服务及收费服务）时，存在违反法律法规的行为，鑫盛计算机科技有权取消会员资格而无须给予任何补偿，且会员须自行承担全部责任。
      <br/>
      <br/>
      <p class="user-agreement-title">六、违约处理</p>
      <br/>
      <br/>
      1.在您使用本服务的过程中，不得存在以下行为：
      <br/>
      <br/>
      （1）未经面具约会明确授权，通过技术手段对服务内容、服务期限、服务费用、交易状态等信息进行修改；
      <br/>
      <br/>
      （2）将会员服务通过非面具约会明确授权的方式（包括但不限于借用、租用、售卖、分享、转让）提供他人使用；
      <br/>
      <br/>
      （3）对面具约会用于保护会员服务的任何安全措施技术进行破解、更改、反操作、篡改或其他破坏；
      <br/>
      <br/>
      （4）未经面具约会事先书面同意，删除会员服务内容上的任何所有权或知识产权声明或标签；
      <br/>
      <br/>
      （5）未经面具约会明确授权，采用收费或免费的方式，在任何公开场合全部或部分展示会员服务内容（但如您的上述行为不构成侵权的除外）；
      <br/>
      <br/>
      （6）其他未经面具约会明示授权许可或违反本协议、法律法规或监管政策、侵犯第三方或面具约会合法权益的行为。
      <br/>
      <br/>
      2.您知悉并同意，如您存在任何违反国家法律法规或监管政策、违反本协议或有损面具约会或/及其关联公司的声誉、利益的行为的，面具约会有权采取以下一项或多项处理措施：
      <br/>
      <br/>
      （1）如本协议对此行为有单独条款约定处理方式的，按照该条款处理；
      <br/>
      <br/>
      （2）采取一种或多种措施制止您的行为及行为产生的后果，如删除/屏蔽相关链接或内容、限制/取消您的账号/账户权限等；
      <br/>
      <br/>
      （3）中断或终止部分或全部会员服务，且您已支付的会员服务费用将不予退还且不获得任何形式的补偿/赔偿；
      <br/>
      <br/>
      （4）如您的行为使面具约会或/及其关联公司遭受任何损失的，您应当承担全部损失赔偿责任并在面具约会要求的时限内完成费用支付。
      <br/>
      <br/>
      <p class="user-agreement-title">七、争议解决和法律适用</p>
      <br/>
      <br/>
      1.本协议是《用户协议》、《隐私政策》、《面具约会平台使用规范》等协议的补充协议，是其不可分割的一部分，与其具有同等法律效力，本协议如与前述协议发生冲突的，以本协议为准。
      <br/>
      <br/>
      2.本协议的解释、效力和执行等均适用中华人民共和国法律。
      <br/>
      <br/>
      3.若会员和面具约会之间发生任何纠纷或争议，首先应友好协商解决，经协商无法解决的，任何一方均有权将争议提交至鑫盛计算机科技住所地有管辖权的人民法院诉讼解决。
    </div>
  </div>
</template>

<script>
export default {
  name: 'VipAgreementXS',
  components: {  }
}
</script>

<style scoped>
.user-agreement {
  text-align: left;
  padding: 15px 15px;
  font-size: 14px;
  line-height: 20px;
}
.user-agreement-top {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.user-agreement-title {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
}
</style>
